.bg-primary-500-important {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
}

* {
  white-space: none !important;
}

@font-face {
  font-family: "AucklandHills";
  src: url(./fonts/AucklandHills.ttf) format("truetype");
}
